<template>
  <div v-loading="settingsLoading">
    <el-form ref="form" :model="settingsData">
      <el-row>
        <el-col :span="24">
          <div class="card-body">
            <div class="py-3 px-2">
              <h4 class="fs-6 text-dark fw-medium">Secure E-Sign</h4>
              <el-switch
                v-model="settings.secure_e_sign.allow_document_e_sign"
                :active-value="true"
                :inactive-value="false"
                active-text="Secure E-Sign"
              >
              </el-switch>
            </div>
            <div
                  class="mb-1"
                  v-if="
                    settings.secure_e_sign.allow_document_e_sign
                  "
                >
                  <el-checkbox-group
                    v-model="settings.secure_e_sign.notify_through"
                  >
                    <el-checkbox label="Send_Email">Send Email</el-checkbox>
                    <el-checkbox label="Send_Sms">Send Sms</el-checkbox>
                    <!-- <el-checkbox label="Send_Whatsapp">Send Whatsapp</el-checkbox>  -->
                  </el-checkbox-group>
                  <span style="color: red" v-if="sendError && !settings.secure_e_sign.notify_through.length" >
                   Please Select Atleast One Option
                   </span>
                </div>
          </div>
          <el-divider class="m-0"></el-divider>
          <div class="card-body">
            <div class="pb-3 px-2">
              <h4 class="fs-6 text-dark fw-medium">Update entity data</h4>
              <p>
                If you used entity fields on document. You can update the entity
                data by that document data.
              </p>
              <div class="d-flex w-100 align-items-center">
                <el-radio-group v-model="settings.update_entity_data">
                  <el-radio :label="0"
                    >Update after document completion</el-radio
                  >
                  <el-radio :label="1"
                    >Update after user finishes the document</el-radio
                  >
                  <el-radio :label="2">Never update</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <el-divider class="m-0"></el-divider>
          <div class="card-body">
            <div class="pb-3 px-2">
              <h4 class="fs-6 text-dark fw-medium">Send attachment</h4>
              <div class="d-flex align-items-center">
                <el-checkbox
                  v-model="settings.email_attachement.attach_pdf"
                ></el-checkbox>
                <div class="px-2 word-break-normal">
                  {{
                    "Send completed document as attachment in mail after document completed."
                  }}
                </div>
              </div>
            </div>
          </div>
          <el-divider class="m-0"></el-divider>
          <div class="card-body">
            <div class="pb-3 px-2">
              <h4 class="fs-6 text-dark fw-medium">Expiration Settings</h4>
              <p>
                Set the expiry date for your document validity so the system
                does it for you automatically.
              </p>
              <div class="d-flex w-100 align-items-center">
                <div style="max-width: 200px">
                  {{
                    getIsMobile
                      ? "Expire sent docs in"
                      : "Expire sent documents in"
                  }}
                </div>
                <div class="pl-2" style="max-width: 180px">
                  <el-input
                    placeholder="1"
                    :min="1"
                    type="number"
                    v-on:input="changeExpireDate"
                    v-model="
                      settings.expiration_settings.expire_documents_in_days
                    "
                    size="medium"
                  >
                    <template slot="append">
                      <i class="el-icon-date"></i> days</template
                    >
                  </el-input>
                </div>
              </div>
              <div class="expiration-reminder-check mt-1">
                <div class="d-flex align-items-center">
                  <div class="px-2 word-break-normal">Expire Date</div>
                  <el-date-picker
                    v-model="settings.expiration_settings.document_expired_date"
                    disabled
                  ></el-date-picker>
                </div>
              </div>
              <div class="expiration-reminder-check mt-1">
                <div class="d-flex align-items-center">
                  <el-checkbox
                    v-model="
                      settings.expiration_settings
                        .send_expiration_reminder_status
                    "
                  ></el-checkbox>
                  <div class="px-2 word-break-normal">
                    {{ getIsMobile ? "Remind in" : "Send First Reminder" }}
                  </div>
                  <el-input-number
                    :min="1"
                    type="number"
                    v-on:input="changeRemainder"
                    v-model="
                      settings.expiration_settings.send_first_reminder_in_days
                    "
                    :disabled="
                      !settings.expiration_settings
                        .send_expiration_reminder_status
                    "
                    :max="
                      settings.expiration_settings.expire_documents_in_days - 1
                    "
                  ></el-input-number>
                  <div class="pl-1">Before Expiration</div>
                </div>
              </div>
              <div class="expiration-reminder-check mt-1">
                <div class="d-flex align-items-center">
                  <el-checkbox
                    v-model="
                      settings.expiration_settings
                        .repeat_expiration_reminder_status
                    "
                  ></el-checkbox>
                  <div class="px-2 word-break-normal">
                    {{ getIsMobile ? "Repeat" : "Repeat Reminder" }}
                  </div>
                  <el-input-number
                    v-model="
                      settings.expiration_settings.repeat_expiration_reminder
                    "
                    :disabled="
                      !settings.expiration_settings
                        .repeat_expiration_reminder_status
                    "
                    :min="1"
                    :max="settings.expiration_settings.expire_documents_in_days"
                  ></el-input-number>
                  <div class="pl-1">in Days</div>
                </div>
              </div>
            </div>
          </div>
          <el-divider class="m-0"></el-divider>
          <div class="card-body">
            <div class="py-3 px-2">
              <h4 class="fs-6 text-dark fw-medium">Auto Reminders Settings</h4>
              <p>
                Automatically send email reminders to signers regarding the
                incompletion of document.
              </p>
              <div class="d-flex w-100 align-items-center mb-1">
                <el-checkbox
                  v-model="
                    settings.auto_remainders_settings.first_reminder_status
                  "
                ></el-checkbox>
                <div class="px-2 word-break-normal" style="max-width: 200px">
                  {{ getIsMobile ? "Remind in" : "Send First Reminder in" }}
                </div>
                <div class="pl-2" style="max-width: 180px">
                  <el-input
                    placeholder="0"
                    type="number"
                    :min="0"
                    :max="settings.expiration_settings.expire_documents_in_days"
                    :disabled="
                      !settings.auto_remainders_settings.first_reminder_status
                    "
                    v-model="
                      settings.auto_remainders_settings
                        .send_first_reminder_in_days
                    "
                    size="medium"
                  >
                    <template slot="append">
                      <i class="el-icon-date"></i> days</template
                    >
                  </el-input>
                </div>
              </div>

              <div class="expiration-reminder-check">
                <div class="d-flex align-items-center">
                  <el-checkbox
                    v-model="
                      settings.auto_remainders_settings.repeat_reminder_status
                    "
                  ></el-checkbox>
                  <div
                    class="px-2 word-break-normal"
                    style="width: 100px; padding-right: 0px !important"
                  >
                    {{ getIsMobile ? "Repeat" : "Repeat Reminder" }}
                  </div>
                  <el-input-number
                    v-model="settings.auto_remainders_settings.repeat_reminder"
                    :disabled="
                      !settings.auto_remainders_settings.repeat_reminder_status
                    "
                    :min="1"
                    :max="settings.expiration_settings.expire_documents_in_days"
                  ></el-input-number>
                  <div class="pl-1">
                    {{ getIsMobile ? "Days" : "Until completed &amp; expired" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-divider class="m-0"></el-divider>
      <el-form-item class="mb-0 pt-2 px-2 text-right">
        <el-button @click="closeAndCancle">Back</el-button>
        <el-button
          type="primary"
          @click="updateDocSettingsData"
          :loading="settingsLoading"
          >Save & Send</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
export default {
  name: "configureDocuments-ConfigBulkSendSettings",
  props: {
    configurableId: String,
    settingsData: Object,
  },
  computed: {
    ...mapGetters("configurableDocuments", [
      "getConfigurableDocumentExpirationSettigns",
      "getConfigurableDocumentUpdateStatus",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
  },
  async mounted() {
    this.settingsLoading = true;
    await this.fetchConfigurableDocumentSettings({
      id: this.configurableId,
    });
    await this.setUserSettings();
    this.settingsLoading = false;
  },
  data() {
    return {
      sendError: false,
      settings: {
        secure_e_sign: {
           allow_document_e_sign: false,
          notify_through: [],
          },
        auto_remainders_settings: {
          send_first_reminder_in_days: 0,
          repeat_reminder: 0,
          repeat_reminder_status: true,
          first_reminder_status: true,
        },
        expiration_settings: {
          send_expiration_reminder_status: true,
          repeat_expiration_reminder_status: true,
          send_first_reminder_in_days: 0,
          is_default: true,
          repeat_expiration_reminder: 0,
          expire_documents_in_days: 0,
          document_expired_date: "",
        },
        update_entity_data: 0,
        email_attachement: {
          attach_pdf: true,
        },
      },
      expirationRemainder: true,
      sendFirstRemainder: true,
      repeatRemainderCheck: true,
      settingsLoading: false,
    };
  },
  methods: {
    ...mapActions({
      fetchConfigurableDocumentSettings:
        "configurableDocuments/fetchConfigurableDocumentExpirationSettignsByID",
    }),
    setUserSettings() {
      console.log(this.getConfigurableDocumentExpirationSettigns);
      let data = this.getConfigurableDocumentExpirationSettigns;
      this.settings.expiration_settings.send_expiration_reminder_status =
        data.expiration_settings.send_expiration_reminder_status;
      this.settings.expiration_settings.repeat_expiration_reminder_status =
        data.expiration_settings.repeat_expiration_reminder_status;
      this.settings.expiration_settings.expire_documents_in_days =
        data.expiration_settings.expire_documents_in_days;
      this.settings.expiration_settings.send_first_reminder_in_days =
        data.expiration_settings.send_first_reminder_in_days;
      this.settings.expiration_settings.repeat_expiration_reminder =
        data.expiration_settings.repeat_expiration_reminder;
      this.settings.auto_remainders_settings.repeat_reminder =
        data.auto_remainders_settings.repeat_reminder;
      this.settings.auto_remainders_settings.send_first_reminder_in_days =
        data.auto_remainders_settings.send_first_reminder_in_days;
      this.settings.auto_remainders_settings.repeat_reminder_status =
        data.auto_remainders_settings.repeat_reminder_status;
      this.settings.auto_remainders_settings.first_reminder_status =
        data.auto_remainders_settings.first_reminder_status;
      this.settings.update_entity_data = data.update_entity_data;
      this.settings.email_attachement.attach_pdf =
        data.email_attachement && data.email_attachement.attach_pdf
          ? data.email_attachement.attach_pdf
          : false;
      this.settings.secure_e_sign.allow_document_e_sign =
        data.secure_e_sign &&
        (data.secure_e_sign.allow_document_e_sign == false ||
          data.secure_e_sign.allow_document_e_sign == true)
          ? data.secure_e_sign.allow_document_e_sign
          : true;
          this.settings.secure_e_sign.notify_through = data.secure_e_sign.notify_through;
      this.changeExpireDate();
    },
    async updateDocSettingsData() {
      if( this.settings.secure_e_sign &&  this.settings.secure_e_sign.notify_through.length<1  ){
          this.sendError=true;

        }else{
      let params = {
        id: this.configurableId,
        configurable_document_id: this.configurableId,
        document_expiration_settings: this.settings,
      };

      await this.$store.dispatch(
        "configurableDocuments/updateConfigurableDocument",
        params
      );
      if (this.getConfigurableDocumentUpdateStatus) {
        this.$emit("updateDocSettingsData");
      }
      console.log(params);
    }
    },
    closeAndCancle() {
      this.$emit("closeAndCancle");
    },
    changeExpireDate() {
      if (this.settings.expiration_settings.expire_documents_in_days < 0) {
        this.settings.expiration_settings.expire_documents_in_days = 1;
        // this.settings.expiration_settings.send_first_reminder_in_days=1
        // this.settings.expiration_settings.repeat_expiration_reminder=1
      } else {
        this.settings.expiration_settings.document_expired_date =
          this.$moment().add(
            this.settings.expiration_settings.expire_documents_in_days,
            "days"
          );
      }
    },
    changeRemainder() {
      if (
        this.settings.expiration_settings.send_first_reminder_in_days >
        this.settings.expiration_settings.expire_documents_in_days
      ) {
        this.settings.auto_remainders_settings.send_first_reminder_in_days =
          this.settings.expiration_settings.expire_documents_in_days;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit(
      "configurableDocuments/setConfigurableDocumentExpirationSettigns",
      null,
      { root: true }
    );
    this.$store.commit(
      "configurableDocuments/setConfigurableDocumentUpdateStatus",
      null,
      { root: true }
    );
  },
};
</script>
<style lang="scss" scoped>
.expiration-reminder-check {
  margin-bottom: 15px;
}
</style>
